/* 
 * Copyright 2023 NeuralBridge AI
 * Licensed under the Apache License, Version 2.0 (the "License");
 * You may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *     http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
 
/**
 * Styles for the footer section.
 * Sets the background color, padding, and flexbox properties for alignment.
 */
 .footer {
  background-color: #2c3e50;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/**
 * Styles for the company name in the footer.
 * Sets font size, weight, color, margin, and text decoration.
 */
.footer__company-name {
  font-size: 24px;
  font-weight: bold;
  color: #ecf0f1;
  margin-bottom: 20px;
  text-decoration: none;
}

/**
 * Container for links in the footer.
 * Uses flexbox for center alignment and sets margin.
 */
.footer__links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

/**
 * Styles for individual links in the footer.
 * Sets margin, color, transition effect, and text decoration.
 */
.footer__link {
  margin-right: 20px;
  color: #bdc3c7;
  transition: color 0.3s ease;
  text-decoration: none;
}

/**
 * Hover effect for footer links.
 * Changes color on hover.
 */
.footer__link:hover {
  color: #3498db;
}

/**
 * Style for the last link in the footer.
 * Removes the margin on the right.
 */
.footer__link:last-child {
  margin-right: 0;
}

/**
 * Styles for the copyright text in the footer.
 * Sets the text color.
 */
.footer__copyright {
  color: #bdc3c7;
}
