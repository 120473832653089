/* 
 * Copyright 2023 NeuralBridge AI
 * Licensed under the Apache License, Version 2.0 (the "License");
 * You may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *     http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Imports global styles and variables from NeuralBridge.css.
 */
 @import "../NeuralBridge.css";

 /**
  * Styles for input-output buttons.
  * Defines border, size, padding, cursor, background color, color, border-radius, 
  * transition effect, font, and overflow behavior.
  */
 .input-output-button {
   border: 2px solid var(--accent-color-blue-1);
   max-height: 100px;
   max-width: 250px;
   padding: 10px;
   margin: 10px;
   cursor: pointer;
   background-color: var(--primary-bg-color);
   color: var(--accent-color-blue-1);
   border-radius: 5px;
   transition: all 0.3s ease;
   font-family: var(--font-heading);
   overflow: hidden;
 }
 
 /**
  * Hover effects for input-output buttons.
  * Changes background color, text color, and adds a box shadow.
  */
 .input-output-button:hover {
   background-color: var(--accent-color-blue-1);
   color: var(--primary-bg-color);
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
 }
 
 .input-output-button:hover .input-output-button__overflow-icon,
 .input-output-button:hover .input-output-button__item {
   color: var(--primary-bg-color);
 }
 
 /**
  * Active state styles for input-output buttons.
  * Applies a box shadow and a slight scale transformation.
  */
 .input-output-button:active {
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   transform: scale(0.97);
 }
 
 /**
  * Styles for the header text within the input-output button.
  * Sets the font size.
  */
 .input-output-button__header--text {
   font-size: 1.2em;
 }
 
 /**
  * Styles for the list within the input-output button.
  * Sets position, list style, padding, margin, overflow behavior.
  */
 .input-output-button__list {
   position: relative;
   list-style: none;
   padding: 0;
   margin: 0;
   overflow: hidden;
 }
 
 /**
  * Styles for individual items in the input-output button list.
  * Sets font, color, size, and padding.
  */
 .input-output-button__item {
   font-family: var(--font-main-text);
   color: var(--primary-text-color);
   font-size: larger;
   padding: 3px 0;
 }
 
 /**
  * Styles for the overflow icon in the input-output button.
  * Sets display, height, color, weight, and font size.
  */
 .input-output-button__overflow-icon {
   display: flex;
   height: 100%;
   color: var(--primary-text-color);
   font-weight: bold;
   font-size: 1.2em;
 }
 
 /**
  * Styles for truncating text within the input-output button.
  * Uses white-space, overflow, text-overflow properties for ellipsis effect.
  */
 .input-output-button__text--truncate {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   max-width: 100%;
   max-height: 100%;
 }
 
 /**
  * Styles for the name within the input-output button.
  * Sets the font weight to bold.
  */
 .input-output-button__name {
   font-weight: bold;
 }
 
 /**
  * Styles for the header icon within the input-output button.
  * Sets display, vertical alignment, and margin.
  */
 .input-output-button__header--icon {
   display: flex;
   vertical-align: middle;
   margin-right: 8px;
 }
 
 /**
  * Styles for the header section within the input-output button.
  * Uses flexbox for alignment.
  */
 .input-output-button__header {
   display: flex;
   align-items: center;
   justify-content: center;
 }
 