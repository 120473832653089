/* 
 * Copyright 2023 NeuralBridge AI
 * Licensed under the Apache License, Version 2.0 (the "License");
 * You may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *     http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Imports the Pacifico font from Google Fonts.
 */
 @import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

 /**
  * Styles for the header section.
  * Applies flexbox layout, padding, and background color.
  */
 .header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 1rem 2rem;
   background-color: #282c34;
 }
 
 /**
  * Styles for the logo inside the header.
  * Sets the font properties and color. Applies a text shadow for depth.
  */
 .header__logo {
   font-family: 'Pacifico', cursive;
   font-size: 2.5rem;
   font-weight: bold;
   letter-spacing: 0.2em;
   color: #f39c12;
   text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
 }
 
 /**
  * Styles for the feedback button in the header.
  * Sets background color and text color.
  */
 .header__feedback {
   background-color: #3498db;
   color: #fff;
 }
 
 /**
  * Hover effect for the feedback button.
  * Changes the background color on hover.
  */
 .header__feedback:hover {
   background-color: #2980b9;
 }
 