/* 
 * Copyright 2023 NeuralBridge AI
 * Licensed under the Apache License, Version 2.0 (the "License");
 * You may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *     http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Global Styles Import
 * Imports global styles and variables from NeuralBridge.css.
 */
 @import "../NeuralBridge.css";

 /**
  * Styles for the thread legend container.
  * Sets width, display type, position, border, padding, margin, background color, text color, 
  * border radius, and font family.
  */
 .thread-legend {
   width: auto;
   display: inline-block;
   position: fixed;
   border: 2px solid var(--accent-color-blue-1);
   padding: 10px;
   margin: 10px;
   background-color: var(--primary-bg-color);
   color: var(--accent-color-blue-1);
   border-radius: 5px;
   font-family: var(--font-heading);
 }
 
 /**
  * Styles for individual items within the thread legend.
  * Applies flex layout for alignment.
  */
 .thread-legend__item {
   display: flex;
   align-items: center;
 }
 
 /**
  * Styles for the color indicator in the thread legend.
  * Sets width, height, margin, and border radius.
  */
 .thread-legend__color {
   width: 20px;
   height: 20px;
   margin: 10px;
   border-radius: 5px;
 }
 
 /**
  * Styles for the title in the thread legend.
  * Sets text alignment, color, font weight, and margin.
  */
 .thread-legend__title {
   text-align: center;
   color: var(--primary-color);
   font-weight: bold;
   margin-bottom: 10px;
 }
 