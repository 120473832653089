/*
 * Copyright 2023 NeuralBridge AI
 * Licensed under the Apache License, Version 2.0 (the "License");
 * You may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *     http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
 
 /* Reset some default styles for body */
 body {
  margin: 0; /* Reset margin to zero */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; /* Specify a common font stack for better cross-platform rendering */
  -webkit-font-smoothing: antialiased; /* Improve font rendering in Webkit browsers */
  -moz-osx-font-smoothing: grayscale; /* Improve font rendering in macOS browsers */
}

/* Style for code elements */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; /* Specify font stack for code elements */
}
