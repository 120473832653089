/* 
 * Copyright 2023 NeuralBridge AI
 * Licensed under the Apache License, Version 2.0 (the "License");
 * You may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *     http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Global Styles Import
 * Imports global styles and variables defined in NeuralBridge.css.
 */
 @import "../NeuralBridge.css";

 /**
  * Styles for react-draggable component.
  * Sets border, margin, background color, text color, border radius, font, and scroll behavior.
  */
 .react-draggable {
   border: 2px solid var(--primary-color);
   margin: 10px;
   background-color: var(--primary-bg-color);
   color: var(--primary-color);
   border-radius: 5px;
   font-family: var(--font-heading);
   overflow-y: scroll;
   overflow-x: scroll;
   z-index: 10000001;
 }
 
 /**
  * Styles for scrollbar within react-draggable.
  * Customizes appearance, width, height, and shadow of scrollbar thumb.
  */
 .react-draggable::-webkit-scrollbar {
   -webkit-appearance: none;
   width: 7px;
   height: 7px;
 }
 
 .react-draggable::-webkit-scrollbar-thumb {
   border-radius: 4px;
   background-color: rgba(97, 97, 97, 0.316);
   -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
 }
 
 .react-draggable::-webkit-scrollbar-thumb:hover {
   background-color: rgba(0, 0, 0, 0.5);
 }
 
 /**
  * Styles for active code window.
  * Adds box shadow and transform effect on active state.
  */
 .code-window:active {
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   transform: scale(0.97);
 }
 
 /**
  * Header styles for code window.
  * Sets font size.
  */
 .code-window__header {
   font-size: 1.2em;
 }
 
 /**
  * Styles for list within the code window.
  * Removes list style and sets padding.
  */
 .code-window__list {
   list-style: none;
   padding: 0;
 }
 
 /**
  * Item styles within code window list.
  * Sets font, color, size, and padding.
  */
 .code-window__item {
   font-family: var(--font-main-text);
   color: var(--primary-text-color);
   font-size: larger;
   padding: 3px 0;
 }
 
 /**
  * Name styles within code window.
  * Sets font weight to bold.
  */
 .code-window__name {
   font-weight: bold;
 }
 
 /**
  * Icon styles within code window.
  * Sets display, alignment, and margin.
  */
 .code-window__icon {
   display: inline-block;
   vertical-align: middle;
   margin-right: 8px;
 }
 
 /**
  * Drag handle styles for code window.
  * Sets cursor, padding, and display.
  */
 .code-window__draghandle {
   cursor: move;
   padding: 10px;
   padding-bottom: 0;
   display: inline-block;
 }
 
 /**
  * Content styles for code window.
  * Sets cursor, padding, and display.
  */
 .code-window__content {
   cursor: text;
   padding: 10px;
   padding-top: 0;
   display: inline-block;
 }
 
 /**
  * Clear button styles for code window.
  * Sets background, border, color, cursor, padding, and margin.
  */
 .code-window__clearbutton {
   background: none;
   border: none;
   color: var(--primary-color);
   font-size: 1.2em;
   cursor: pointer;
   padding: 0;
   margin: 0;
   margin-left: 10px;
 }
 
 /**
  * Hover effect for clear button's icon in code window.
  * Applies transform scale and transition effect.
  */
 .code-window__clearbutton:hover .code-window__icon {
   transform: scale(1.1);
   transition: transform 0.3s ease;
 }
 
 /**
  * Styles for resizable handle in react components.
  * Sets position to fixed.
  */
 .react-resizable-handle {
   position: fixed;
 }
 