/*
 * Copyright 2023 NeuralBridge AI
 * Licensed under the Apache License, Version 2.0 (the "License");
 * You may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *     http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * CSS Styles for the 'versionCheck__alert' element.
 * This class is used to style an alert element for version checking.
 */
.versionCheck__alert {
  display: flex;
  align-items: center;
}

/**
 * CSS Styles for the 'versionCheck__copy-icon' element.
 * This class is used to style an icon element within the version check alert.
 */
.versionCheck__copy-icon {
  margin-left: 8px; /* Adjust the space as needed */
}

/**
 * CSS Styles for the 'versionCheck__command' element.
 * This class is used to style a command text element in lowercase.
 * The 'text-transform' property ensures the text is always displayed in lowercase.
 */
.versionCheck__command {
  text-transform: lowercase; /* This will ensure the text is always in lowercase */
}