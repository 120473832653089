/* 
 * Copyright 2023 NeuralBridge AI
 * Licensed under the Apache License, Version 2.0 (the "License");
 * You may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *     http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Global Styles Import
 * Imports global styles and variables defined in NeuralBridge.css.
 */
 @import "../NeuralBridge.css";

 /**
  * Styles for react-draggable components.
  * Sets border, margin, background color, text color, border radius, font, and overflow behavior.
  * Adjusts z-index for stacking context.
  */
 .react-draggable {
   border: 2px solid var(--primary-color);
   margin: 10px;
   background-color: var(--primary-bg-color);
   color: var(--primary-color);
   border-radius: 5px;
   font-family: var(--font-heading);
   overflow-y: scroll;
   overflow-x: hidden;
   z-index: 100000001;
 }
 
 /**
  * Styles for scrollbar within react-draggable.
  * Customizes appearance and width of scrollbar thumb.
  */
 .react-draggable::-webkit-scrollbar {
   -webkit-appearance: none;
   width: 7px;
 }
 
 .react-draggable::-webkit-scrollbar-thumb {
   border-radius: 4px;
   background-color: rgba(97, 97, 97, 0.316);
   -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
 }
 
 .react-draggable::-webkit-scrollbar-thumb:hover {
   background-color: rgba(0, 0, 0, 0.5);
 }

 .input-output-window {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start of the cross axis */
  }
 
 /**
  * Styles for the header of input-output window.
  * Applies flex layout for alignment.
  */
 .input-output-window__header {
   display: flex;
   align-items: center;
 }
 
 /**
  * Active state styles for input-output window.
  * Adds box shadow and transform effect on active state.
  */
 .input-output-window:active {
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   transform: scale(0.97);
 }
 
 /**
  * Title styles for input-output window.
  * Sets font size.
  */
 .input-output-window__title {
   font-size: 1em;
 }
 
 /**
  * Styles for the list within input-output window.
  * Removes list style and sets padding.
  */
 .input-output-window__list {
   list-style: none;
   padding: 0;
 }
 
 /**
  * Item styles within input-output window list.
  * Sets font, color, size, and padding.
  */
  .input-output-window__item {
    align-self: flex-start; /* Align this item to the start */
    font-family: var(--font-main-text);
    color: var(--primary-text-color);
    font-size: medium;
    padding: 3px 0;
    vertical-align: top; /* This might not be necessary if flexbox is used */
  }
  
 
 /**
  * Name styles within input-output window.
  * Sets font weight to bold.
  */
 .input-output-window__name {
   font-weight: bold;
 }
 
 /**
  * Icon styles within input-output window.
  * Sets display, alignment, and margin.
  */
 .input-output-window__icon {
   display: inline-block;
   vertical-align: middle;
   margin-right: 8px;
 }
 
 /**
  * Drag handle styles for input-output window.
  * Sets cursor, padding, and display.
  */
 .input-output-window__draghandle {
   cursor: move;
   padding: 10px;
   padding-bottom: 0;
 }
 
 /**
  * Content styles for input-output window.
  * Sets cursor, padding, display, and overflow behavior.
  */
  .input-output-window__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the start */
    cursor: text;
    padding: 10px;
    padding-top: 0;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  
 
 /**
  * Clear button styles for input-output window.
  * Sets background, border, cursor, outline, and display.
  * Hover effect scales the icon.
  */
 .input-output-window__clearbutton {
   background: none;
   border: none;
   cursor: pointer;
   outline: none;
   display: flex;
 }
 
 .input-output-window__clearbutton:hover .input-output-window__icon {
   transform: scale(1.1);
   transition: transform 0.3s ease;
 }
 
 /**
  * Styles for resizable handle in react components.
  * Sets position to fixed.
  */
 .react-resizable-handle {
   position: fixed;
 }
 