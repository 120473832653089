/* 
 * Copyright 2023 NeuralBridge AI
 * Licensed under the Apache License, Version 2.0 (the "License");
 * You may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *     http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

 /**
 * Global Styles Import
 * Imports global styles and variables from NeuralBridge.css.
 */
 @import "../NeuralBridge.css";

/* Block: trace-graph-node */
/* Styles for the main container of the trace graph node. */
.trace-graph-node {
  min-width: 250px;
  max-width: 400px;
  min-height: 200px;
  max-height: 600px;
  padding: 10px;
  border: 2px solid var(--accent-color-blue-1);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
  overflow: ellipsis;
}

/* Styles for the title text within the trace graph node. */
.trace-graph-node__title-text {
  color: var(--primary-text-color);
  font: --font-heading;
}

/* Styles for title, inputs, and return buttons in the trace graph node. */
.trace-graph-node__title,
.trace-graph-node__inputs-button,
.trace-graph-node__return-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 2px solid var(--accent-color-blue-1);
  padding: 5px 0;
}

.trace-graph-node__color-box {
  width: 20px;
  height: 20px;
  margin: 10px;
  border-radius: 5px;
}
.input-output-button {
  box-sizing: border-box;
  width: 100%; /* Makes width responsive to the container */
  height: auto; /* Adjust height as per content */
}

/* Container for code and latency, title and nav */
.trace-graph-node__code-latency-container,
.trace-graph-node__title-nav-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* Styles for code and latency sections in the trace graph node. */
.trace-graph-node__code,
.trace-graph-node__latency {
  width: 49%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: top;
  /* border-top: 2px solid var(--accent-color-blue-1); */
  padding: 5px 0;
}

/* Styles for the code display in the trace graph node. */
.trace-graph-node__code {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
  white-space: pre-wrap;
  font-family: monospace;
  overflow: auto;
  max-height: 100px;
  border-right: 2px solid var(--accent-color-blue-1);
  padding-right: 5px;
}

/* Styles for the latency display in the trace graph node. */
.trace-graph-node__latency {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Styles for latency text and value in the trace graph node. */
.trace-graph-node__latency-text,
.trace-graph-node__latency-value {
  font: --font-main-text;
  color: var(--primary-text-color);
  font-size: larger;
}

/* Popup styles for inputs and returns in the trace graph node. */
.trace-graph-node__popup {
  position: absolute;
  bottom: 0;
  right: 100%; /* display to the left of the node */
  z-index: 10;
  border: 1px solid --accent-color;
  padding: 10px;
  background-color: --primary-bg-color;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  overflow: auto;
}

/* Styles for buttons in the code and navigation sections of the trace graph node. */
.trace-graph-node__code button,
.trace-graph-node__nav button {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 2px solid var(--accent-color-blue-1);
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  background-color: var(--primary-bg-color);
  color: var(--accent-color-blue-1);
  border-radius: 5px;
  transition: all 0.3s ease;
  font-family: var(--font-heading);
}


.trace-graph-node__nav button {
  padding: 0px;
  margin: 2px;
}

/* Hover styles for code and navigation buttons. */
.trace-graph-node__code button:hover,
.trace-graph-node__nav button:hover {
  background-color: var(--accent-color-blue-1);
  color: var(--primary-bg-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Focus styles for code and navigation buttons. */
.trace-graph-node__code button:focus,
.trace-graph-node__nav button:focus {
  outline: none; /* Remove default browser outline */
  box-shadow: 0 0 0 2px --primary-text-color;
}

/* Icon styles for code buttons. */
.trace-graph-node__code-button-icon {
  font-size: inherit; /* ensures icon size matches the text */
}

/* Text styles for code buttons. */
.trace-graph-node__code-button-text {
  font-family: --font-heading;
  font-size: 1.2em;
}

/* Styles for the navigation section of the trace graph node. */
.trace-graph-node__nav {
  padding: 10px;
  display: flex;
  align-items: center;
}

/* Styles for navigation text in the trace graph node. */
.trace-graph-node__nav span {
  color: var(--primary-text-color);
  font: --font-heading;
}